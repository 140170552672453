const anchors = document.querySelectorAll('a.scroll-to');


for (let anchor of anchors) {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        const blockID = anchor.getAttribute('href');

        function scrollIntoViewWithOffset(selector, offset) {
            window.scrollTo({
                behavior: 'smooth',
                top:
                    document.querySelector(selector).getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    offset,
            });
        };

        scrollIntoViewWithOffset(blockID, 130);
    });
}