function validate(form) {
    const validation = new JustValidate(form);

    validation
        .addField(`${form} .form-input-name`, [
            {
                rule: 'minLength',
                value: 3,
            },
            {
                rule: 'maxLength',
                value: 30,
            },
            {
                rule: 'required',
                value: true,
                errorMessage: 'Enter your name!'
            }
        ])
        /* .addField(`${form} .form-input-company`, [
            {
                rule: 'required',
                value: true,
                errorMessage: 'Required field!',
            },
        ]) */
        .addField(`${form} .form-input-phone`, [
            {
                rule: 'minLength',
                value: 3,
            },
            {
                rule: 'required',
                value: true,
                errorMessage: 'Phone required',
            }
        ])
        .addField(`${form} .form-textarea-message`, [
            {
                rule: 'required',
                value: true,
                errorMessage: 'Message required'
            }
        ])
        .addField(`${form} .form-input-email`, [
            {
                rule: 'required',
                value: true,
                errorMessage: 'Email is required',
            },
            {
                rule: 'email',
                value: true,
                errorMessage: 'Please enter a valid Email',
            },
        ]).onSuccess((event) => {

            let formData = new FormData(event.target);
            let xhr = new XMLHttpRequest();

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        console.log('done');
                    }
                }
            };

            xhr.open('POST', 'mail.php', true);
            xhr.send(formData);

            event.target.reset();
        });
}

validate('.form');



function inputNum(input, sliceNum) {

    let num = document.querySelector(input);

    $(num).on('input', function () {
        $(this).val($(this).val().replace(/\D/, ''));
    });

    num.oninput = function () {
        this.value = this.value.slice(0, sliceNum);
    };
}

inputNum('.form-input-phone', 13);










