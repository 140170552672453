document.addEventListener('DOMContentLoaded', function () {

    new WOW().init();

    function header() {

        let header = document.querySelector('.header');
        let topOffset = getBodyScrollTop();

        function getBodyScrollTop() {
            return self.pageYOffset || (document.documentElement && document.documentElement.scrollTop) || (document.body && document.body.scrollTop);
        }

        if (topOffset > 1) {
            header.classList.add('fixed');
        } else {
            header.classList.remove('fixed');
        }

        window.addEventListener('scroll', function () {
            let scrollTop = window.scrollY;
            if (scrollTop > 1) {
                header.classList.add('fixed');
            } else {
                header.classList.remove('fixed');
            }
        });
    }

    header();





    if ($(window).width() <= 991) {
        $('*').removeClass('animate__delay-2s animate__delay-1s');
        $('.animate__animated').attr('data-wow-offset', '100');
    }

    function loadData() {
        return new Promise((resolve, reject) => {
            // setTimeout не является частью решения
            // Код ниже должен быть заменен на логику подходящую для решения вашей задачи
            setTimeout(resolve, 3000);
        });
    }

    loadData()
        .then(() => {
            let preloaderEl = document.getElementById('preloader');
            preloaderEl.classList.add('hidden');
        });


    const rect = document.querySelector('.logo');
    const logo = document.querySelector('.preloader__logo');
    const headerFix = document.querySelector('.header');

    let bodyRect = headerFix.getBoundingClientRect(),
        elemRect = rect.getBoundingClientRect(),
        offsetTop = elemRect.top - bodyRect.top,
        offsetLeft = elemRect.left - bodyRect.left;

    gsap.to(logo, {
        duration: 2,
        delay: 1,
        scale: 1,
        transform: 'none',
        left: offsetLeft,
        top: offsetTop,
    });

    let allBtn = document.querySelectorAll('.bitrix-form');

    allBtn.forEach(item => {
        item.addEventListener('click', function (e) {
            e.preventDefault();
        });
    });

});