

const swiper = new Swiper(".promo-swiper", {
    slidesPerView: 1,
    loop: true,
    speed: 600,
    autoplay: {
        delay: 10000,
        disableOnInteraction: false,
    },
    on: {
        init: function () {
            let thisSlider = this;
            thisSlider.autoplay.pause();
            setTimeout(function () {
                thisSlider.autoplay.run();
            }, 10000);
        },
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    effect: "creative",
    creativeEffect: {
        prev: {
            shadow: true,
            translate: ["-20%", 0, -1],
        },
        next: {
            translate: ["100%", 0, 0],
        },
    },
});